.card {
    border-radius: 8px;
    box-shadow: 0 0 8px #ccc;
    color: #253b56;
    margin: 12px 0;
    padding: 12px;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.table {
    max-width: 800px;
}

.leftColumn {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 1em;
}

.rightColumn {
    flex-grow: 1;
    border-left: 1px solid #ccc;
    padding-left: 1em;
    display: flex;
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.buttontext {
    padding-right: 1em;
}

.content {
    padding: 4px 16px 20px 16px;
}

.content_centered {
    display: flex;
    justify-content: center;
}

.cardTitle {
    margin: 12px 0 0 0;
}

.item {
    margin-bottom: 6px;
    display: flex;;
    flex-direction: row;
}

.description {
    display: flex;
    flex-direction: column;
}

.label {
}

.text {
    opacity: 0.8;
}

.editContainer {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}

.editIcon {
    color: #00B3E6;
}


.ersatzImage {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    background-color: #7f7f7f;
    color: white;
    font-size: 50px;
    width: 120px;
    height: 120px;
    font-style: normal;
    text-transform: uppercase;
}

