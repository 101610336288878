body {
    background-color: transparent !important;
}

.navbar {
    background-color: var(--dark);
    color: white;
    width: 250px;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1;
}

.navLink {
    color: white;
}

.secondLevel {
    font-size: 87.5%;
    padding-left: 3em !important;
}

.navLink:global(.active) {
    background-color: white;
    color: var(--dark);
}

.navLink:hover {
    color: var(--primary);
    cursor: pointer;
}

.bottom {
    position: fixed;
    bottom: 10px;
    margin-left: 45px;
}

.navLogo {
    padding: 1em;
    margin: 1em auto 2em;
}

.icon {
    float: right;
    margin-right: 1em;
    margin-top: 3px;
}

.router {
    margin-left: 250px;
    padding: 2em;
}
