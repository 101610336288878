.background {
    background-image: url('background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
