.infoContainer {
    display: flex;
    justify-content: space-between;
}

.refundButton {
    align-self: flex-end;
    margin-top: auto;
}

.headline {
    margin-top: 30px;
    font-size: 1.5rem;
}

.columns {
    display: flex;
    gap: 150px;
}

.column1 {
    flex: 1;
}

.card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 20px;
    max-width: 600px;
    width: 100%;
    border: 2px solid #dee2e6;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
}
