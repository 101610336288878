.infoContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.manageApplianceForm {
    border-left: black 0.1em solid;
    padding-left: 1em;
    width: 70%;
}

.installationVerificationInfoText {
    margin-bottom: 1em;
}

.actionButton {
    margin-top: 0.5em;
}

.historyTable {
    border-collapse: separate;
    border-spacing: 1em 0.1em;
}

.infoTable {
    border-collapse: separate;
    border-spacing: 0.5em 0.2em;
    margin-left: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.maintenanceScheduleTable {
    border-collapse: separate;
    border-spacing: 1.5em 0.2em;
    margin-left: 1.0em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.maintenanceScheduleCommentColumn {
    word-break: break-word;
}

.addMaintenanceScheduleButton {
    margin-top: 0.2em;
    margin-left: 2.5em;
}

table.infoTable td:nth-child(2) {
    color: #3B3B3A;
}

.infoTable tr td.separator {
    border-top: thin solid #A3A3A3;
}

.actionHeader {
    margin-top: 1.33rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.subActionHeader {
    margin-top: 1.0rem;
    margin-bottom: 1.0rem;
    font-size: 1.33rem;
    font-weight: 500;
    line-height: 1.0;
}

.subAction {
    margin-left: 2rem;
}
