.clickable {
    cursor: pointer;
}

.wewashHeading {
    text-align: center;
    color: var(--primary);
    padding: 3em;
}

.blink_me {
    animation: blinker 1s linear backwards;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}