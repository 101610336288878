.infoContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.topUpForm {
    border-left: black 0.1em solid;
    padding-left: 1em;
    width: 70%;
}