.container > :global(.row) {
    margin: 10px;
}

.container table {
    font-size: 0.9rem;
}

.adminButtonRow {
    flex-direction: row;
    justify-content: space-between;
}
