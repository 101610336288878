.logo {
    width: 50%;
    position: relative;

    left: 50%;
    transform: translate(-50%, -0%);
}

.card {
    width: 40%;
    min-width: 300px !important;
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
}

.heading {
    text-align: center;
    color: var(--dark);
    font-size: 20px;
    font-weight: 300;
}

.signInButton {
    width: 100%;
    margin-bottom: 3em;
}
