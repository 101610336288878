.clientReconnectionInfoText {
    margin-top: 1em;
}

.actionButton {
    margin-top: 0.5em;
}

.section {
    margin-top: 2.0em;
}

.infoTable {
    border-collapse: separate;
    border-spacing: 0.5em 0.2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}