.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
    transition-duration: 25ms;
}

.card {
    transition: 0.3s;
}

.horizontal-container-flex {
    flex-wrap: wrap;
}