.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
    transition-duration: 25ms;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    border: 2px solid grey;
}
.card:hover {
    cursor: pointer;
}

.horizontal-container-flex {
    flex-wrap: wrap;
    display: inline-block;
    align-content: center;
    margin: 0.5rem;
}