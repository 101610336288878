// Override default variables before the import
$theme-colors: (
        "primary": #00B3E6, // Cerulean
        "dark": #006DB6, // Dark Cerulean
        "danger": #CC0000, // Guardsman Red
        "info": #A1A19D, // Light Grey
        "secondary": #3B3B3A, // Tuatara
);

* {
  font-family: "Ubuntu Light", sans-serif;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
