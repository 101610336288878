.invoiceItemList {
    margin: 1em 0;
}

table.invoiceItemList > thead > tr {
    background: #F5F6F7;
}

table.invoiceItemList > tbody > tr:nth-child(2n) {
    background: #F5F6F7;
}

table.invoiceItemList > thead > tr > td {
    text-align: left;
}

.invoiceItemEntry {
    padding: 0.2em 0.5em;
}

.spacerAbove {
    margin-top: 0.5em;
}