.container > :global(.row) {
    margin: 0 2em;
}

.spacer {
    padding-top: 1em;
}

.value {
    padding-left: 0.5em;
}

.alarm {
    color: var(--danger);
}
