
.creditNotesSummaryTable {
    margin-top: 2rem;
}

.creditNotesSummaryTable tbody tr:nth-child(8n+1),
.creditNotesSummaryTable tbody tr:nth-child(8n+2),
.creditNotesSummaryTable tbody tr:nth-child(8n+3),
.creditNotesSummaryTable tbody tr:nth-child(8n+4) {
    background-color: #efefef;
}

.creditNotesSummaryTable tbody tr:nth-child(8n+5),
.creditNotesSummaryTable tbody tr:nth-child(8n+6),
.creditNotesSummaryTable tbody tr:nth-child(8n+7),
.creditNotesSummaryTable tbody tr:nth-child(8n+0) {
    background-color: #fff;
}


.textWithTooltip {
    padding-left: 3px;
    padding-right: 3px;
    border-bottom: 1px dotted var(--dark);
    color: var(--dark);
}

.numberCell {
    text-align: right;
}

.buttonCell {
    text-align: center;
}


