

.item {
    margin-bottom: 6px;
    display: flex;;
    flex-direction: row;
}

.description {
    display: flex;
    flex-direction: column;
}

.label {
}

.text {
    opacity: 0.8;
}

.editContainer {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}

.editIcon {
    color: #00B3E6;
}

