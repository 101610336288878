.table {
    word-break: break-all;
}

.clickable {
    cursor: pointer;
}

.header {
    color: var(--secondary);
}

.body {
    color: var(--secondary);
}

.alarm {
    color: var(--danger);
}

.warning {
    color: var(--primary);
}

.icon {
    color: var(--primary);
}

.bigInputForSearchingOrFiltering {
    background-position: 10px 12px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    width: 100%; /* Full-width */
    font-size: 16px; /* Increase font-size */
    padding: 12px 20px 12px 20px; /* Add some padding */
    border: 1px solid #ddd; /* Add a grey border */
    margin-bottom: 12px; /* Add some space below the input */
}

.midSizedInputForSearchingOrFiltering {
    background-position: 10px 12px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    width: 60%;
    font-size: 16px; /* Increase font-size */
    padding: 12px 20px 12px 20px; /* Add some padding */
    border: 1px solid #ddd; /* Add a grey border */
    margin-bottom: 12px; /* Add some space below the input */
    margin-right: 12px; /* Add some space below the input */
}
